<!--大厦服务-->
<template>
  <div class="main-div">
    <!--面包屑-->
    <el-row class="outerRow">
      <el-col>
        <top-path-list :path-obj="pathObj"></top-path-list>
      </el-col>
    </el-row>
    <el-row class="outerRow">
      <el-col class="rowSpaceBetween">
        <!--   左侧菜单栏   -->
        <div class="leftDiv">
          <left-menu :menu-list="leftMenu" @menuActive="clickMenu"></left-menu>
        </div>

        <!--   右侧区域   -->
        <div class="rightDiv">
          <div class="rulesDiv">
            <div class="rulesTitle">
              {{menuName}}
            </div>
            <div class="line-class"></div>
            <div style="width: 100%; margin-bottom: 20px"></div>
            <div class="w-e-text-container">
              <div v-html="content[menuIndex]" data-slate-editor></div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import topPathList from '@/components/topPathList'
import { giEdificeList } from '@/utils/commonApi'
import leftMenu from '@/components/leftMenu'

export default {
  name: 'buildingService',
  components: { topPathList, leftMenu },
  data() {
    return {
      pathObj: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '地标大厦',
          path: '/giEdifice/index'
        },
        {
          name: '周边配套',
          path: '/giEdifice/buildingService'
        },
      ],
      content: [],
      leftMenu: [],
      menuIndex: 0,
      menuName: '',
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    clickMenu({ menu, index }) {
      this.menuName = menu.name;
      this.menuIndex = index
    },
    initData() {
      let params = {
        moduleName: '1'
      }
      giEdificeList(params).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          let data = res.data.data;
          if (data && data.length > 0) {
            data.forEach(item => {
              this.leftMenu.push({ name: item.typeName });
              this.content.push(item.articleInfo)
            })
            this.menuName = this.leftMenu[0].name;
            this.$forceUpdate()
          }
        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    },
  }
}
</script>

<style scoped lang="less">
@import '~@/assets/css/common.less';
::v-deep .w-e-text-container img {
  max-width: 100% !important;
}
.main-div {
  background-color: #f8f9fa !important;
}
.leftDiv {
  width: 15%;
  text-align: center;
}
.rightDiv {
  width: 82%;
  background: white;
  border-radius: 5px;
  margin-bottom: 40px;
}
.rulesDiv {
  transition: all .2s ease;
  background: #FFFFFF;
  box-shadow: 0 3px 12px 0 rgba(178, 178, 178, 0.26);
  border-radius: 5px;
  box-sizing: border-box;
  padding: 23px 24px;
  min-height: 517px;

  .rulesTitle {
    font-size: 20px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #1A5BC5;
    line-height: 30px;
    text-align: left;
  }

  .line-class {
    margin-top: 20px;
    width: 910px;
    border: 1px solid #EBEFF6;
  }
}
</style>
