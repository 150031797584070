import { render, staticRenderFns } from "./buildingService.vue?vue&type=template&id=fa658898&scoped=true"
import script from "./buildingService.vue?vue&type=script&lang=js"
export * from "./buildingService.vue?vue&type=script&lang=js"
import style0 from "./buildingService.vue?vue&type=style&index=0&id=fa658898&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa658898",
  null
  
)

export default component.exports